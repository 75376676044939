
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.s-calculated-field {
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $base-gutter;

    .esmp-select2__container {
      min-height: 38px;
    }
  }
  &__settings {
    margin-bottom: $base-gutter;
  }
  &__default-calc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: $base-gutter / 2;
    grid-template-areas:
    "dot singleQuote semicolon plus ifMore"
    "number7 number8 number9 minus ifLess"
    "number4 number5 number6 multiplication leftParenthesis"
    "number1 number2 number3 division rightParenthesis"
    "number0 number0 number0 ifEqual ifEqual";
  }
  &__setting {
    min-width: 50px;
    font-weight: bold;
  }
  &__select {
    display: flex;
    align-items: center;
    gap: $base-gutter;
    margin-bottom: $base-gutter;
  }
  &__hint {
    border: 1px solid $color-primary-1-day;
    border-radius: $base-border-radius;
    padding: $base-gutter / 2;
  }

  &__formula {
    position: relative;

    textarea {
      font-family: 'Basis Grotesque Pro', sans-serif;
    }
  }
}
